<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Identify the correct lattice for each of these crystal structures.</p>

      <p class="mb-2">
        <v-img class="mb-2" style="max-width: 150px" src="/img/assignments/UofA/q1_4a.png" />
        Cesium chloride:
      </p>
      <p class="mb-4">
        <v-select
          v-model="inputs.input1"
          class="ml-3"
          style="display: inline-block; max-width: 150px"
          :items="options"
          item-text="text"
          item-value="value"
          label="Select Option"
        ></v-select>
      </p>

      <p class="mb-2">
        <v-img class="mb-2" style="max-width: 150px" src="/img/assignments/UofA/q1_4b.png" />
        Elemental iron:
      </p>
      <p class="mb-4">
        <v-select
          v-model="inputs.input2"
          class="ml-3"
          style="display: inline-block; max-width: 150px"
          :items="options"
          item-text="text"
          item-value="value"
          label="Select Option"
        ></v-select>
      </p>

      <p class="mb-2">
        <v-img class="mb-2" style="max-width: 150px" src="/img/assignments/UofA/q1_4c.png" />
        Sodium chloride:
      </p>
      <p class="mb-4">
        <v-select
          v-model="inputs.input3"
          class="ml-3"
          style="display: inline-block; max-width: 150px"
          :items="options"
          item-text="text"
          item-value="value"
          label="Select Option"
        ></v-select>
      </p>

      <p class="mb-2">
        <v-img class="mb-2" style="max-width: 150px" src="/img/assignments/UofA/q1_4d.png" />
        C60:
      </p>
      <p class="mb-4">
        <v-select
          v-model="inputs.input4"
          class="ml-3"
          style="display: inline-block; max-width: 150px"
          :items="options"
          item-text="text"
          item-value="value"
          label="Select Option"
        ></v-select>
      </p>

      <p class="mb-2">
        <v-img class="mb-2" style="max-width: 150px" src="/img/assignments/UofA/q1_4e.png" />
        Copper-gold:
      </p>
      <p class="mb-4">
        <v-select
          v-model="inputs.input5"
          class="ml-3"
          style="display: inline-block; max-width: 150px"
          :items="options"
          item-text="text"
          item-value="value"
          label="Select Option"
        ></v-select>
      </p>

      <p class="mb-2">
        <v-img class="mb-2" style="max-width: 150px" src="/img/assignments/UofA/q1_4f.png" />
        Elemental copper:
      </p>
      <p class="mb-4">
        <v-select
          v-model="inputs.input6"
          class="ml-3"
          style="display: inline-block; max-width: 150px"
          :items="options"
          item-text="text"
          item-value="value"
          label="Select Option"
        ></v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUofA103SandboxQ1_4',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
      options: [
        {text: 'Body-centered cubic', value: 'bodyCentered'},
        {text: 'Face-centered cubic', value: 'faceCentered'},
        {text: 'Primitive cubic', value: 'primitive'},
      ],
    };
  },
};
</script>
<style scoped></style>
